/** React library */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/** React Icons */
import { AiFillCamera } from "react-icons/ai";

/** React bootstrap */
import { Alert, Button, Modal, Spinner } from "react-bootstrap";

/** Project library */
import ImageCropper from 'shared/Cropper';

/** Style */
import './ImageEditor.scss';

const _imageEditorData = {
    image: null,
    file: null,
    error: '',
    cropImageRunning: false
};

function ImageEditor(props) {
    const imageSize = props.size ?? { width: null, height: 40 };
    const [imageEditorData, setImageEditorData] = useState(_imageEditorData);
    const [imageEditorShow, setImageEditorShow] = useState(props.show);
    const [inProgress, setInProgress] = useState(props.inProgress);
    const [pageLoading, setPageLoading] = useState(true);
    const [cropCompleteConfig, setCropCompleteConfig] = useState({});

    useEffect(() => {
        setImageEditorShow(props.show);
        setInProgress(props.inProgress);
    }, [props.show, props.inProgress]);

    useEffect(() => {
        async function loadImage() {
            if (props.usePreviousImage) {
                try {
                    let name = props.usePreviousImage.split('/');
                    name = name[name.length - 1];
                    const response = await fetch(props.usePreviousImage);
                    const data = await response.blob();
                    const file = new File([data], name, { type: data.type });
                    const reader = new FileReader();
                    reader.addEventListener('load', () => {
                        setImageEditorData({
                            ..._imageEditorData,
                            image: reader.result?.toString() || '',
                            file: file,
                            error: ""
                        });
                        setPageLoading(false);
                    })
                    reader.readAsDataURL(file);
                } catch (error) {
                    setImageEditorData({
                        ..._imageEditorData,
                    });
                    setPageLoading(false);
                }

            } else {
                setImageEditorData({
                    ..._imageEditorData,
                });
                setPageLoading(false);
            }
        }
        loadImage();
    }, [props.usePreviousImage]);

    const previewImageChange = (event) => {
        if (imageSize.width === null || imageSize.height === null) {
            setCropCompleteConfig({
                crop: {
                    unit: 'px',
                    width: imageSize.width ?? imageSize.height + imageSize.height,
                    height: imageSize.height ?? imageSize.width + imageSize.width,
                    x: 0,
                    y: 0
                }
            });
            // let img = new Image()
            // img.src = window.URL.createObjectURL(event.target.files[0])
            // img.onload = () => {

            // }
            // return img;
        }
        else {
            setCropCompleteConfig({
                crop: {
                    unit: 'px',
                    width: imageSize.width ?? undefined,
                    height: imageSize.height ?? undefined,
                    x: 0,
                    y: 0
                }
            })
        }
        const file = event.target.files[0];
        if (file) {

            const allowedFiles = ['image/jpeg', 'image/jpg', 'image/gif', 'image/png', 'image/webp']
            if (allowedFiles.includes(file.type)) {
                const reader = new FileReader()
                reader.addEventListener('load', () => {
                    setImageEditorData({
                        ...imageEditorData,
                        image: reader.result?.toString() || '',
                        file: file,
                        error: ""
                    });
                })
                reader.readAsDataURL(file);

            } else {
                setImageEditorData({
                    ...imageEditorData,
                    image: null,
                    file: null,
                    error: "Invalid Image"
                });
            }
        }
    }

    const onCropComplete = (file, data) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => {
            setImageEditorData({
                ...imageEditorData,
                cropImageRunning: false,
                image: reader.result?.toString() || '',
                file: file,
                error: "",
            });
        })
        reader.readAsDataURL(file);
    }

    const cropImageRunning = (flag) => {
        setImageEditorData({
            ...imageEditorData,
            cropImageRunning: flag
        })
    }

    const resetImageEditor = () => {
        setImageEditorData(_imageEditorData);
    }

    const cancelImageEdit = () => {
        props.onCancel();
    }

    const uploadImageFile = () => {
        props.onComplete(imageEditorData);
    }


    return (
        <div>
            <Modal show={imageEditorShow} className="preview-image-modal" size="xl">
                <Modal.Body>
                    {
                        pageLoading ? <div className='text-center my-5 py-5'>
                            <Spinner variant='danger' animation='border'></Spinner>
                        </div> :
                            <div>
                                <Modal.Title>Add Image</Modal.Title>
                                {
                                    !imageEditorData.image ?
                                        <div className="image-select">
                                            <label htmlFor="images" className="image-container">
                                                <div className="image-title"><AiFillCamera className="mb-0" /></div>
                                                <div>{props.size.width} x {props.size.height}</div>
                                                <div>Click to add image</div>
                                                {
                                                    imageEditorData.error ?
                                                        <Alert variant="danger">Please select valid image</Alert>
                                                        : null
                                                }
                                                <input type="file" id="images" accept="image/jpeg,image/gif,image/png,image/webp" onChange={previewImageChange}></input>
                                            </label>
                                        </div> :
                                        <div>
                                            {
                                                imageEditorData.cropImageRunning ?
                                                    <ImageCropper onCropComplete={onCropComplete}
                                                        file={imageEditorData.file} config={{ crop: cropCompleteConfig?.crop }} imgSrc={imageEditorData.image} /> :
                                                    <img className="uploaded-image" src={imageEditorData.image} alt="preview"></img>
                                            }

                                        </div>
                                }

                                <div className="mt-2">
                                    <div className="d-flex align-items-center justify-content-end">
                                        <Button variant="outline-info me-2" onClick={cancelImageEdit}>Cancel</Button>
                                        <Button variant="outline-info" onClick={resetImageEditor}>Remove Image</Button>
                                        {
                                            imageEditorData.image ?
                                                <div>
                                                    {
                                                        imageEditorData.cropImageRunning ?
                                                            <Button variant="outline-info mx-2" onClick={() => { cropImageRunning(false); }}>Cancel Cropping</Button>
                                                            :
                                                            <Button variant="outline-info mx-2" onClick={() => { cropImageRunning(true); }}>Crop Image</Button>

                                                    }
                                                    {
                                                        imageEditorData.cropImageRunning ? null :
                                                            inProgress ?
                                                                <Button variant="info" className="text-white" disabled={true}>
                                                                    <span className="d-flex align-items-center justify-content-center">
                                                                        <Spinner
                                                                            as="span"
                                                                            animation="border"
                                                                            role="status"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </span>
                                                                </Button> :
                                                                <Button variant="info" onClick={uploadImageFile}>Add</Button>
                                                    }
                                                </div> : null
                                        }
                                    </div>
                                </div>
                            </div>
                    }
                </Modal.Body>
            </Modal>
        </div>
    )
}

ImageEditor.propTypes = {
    onComplete: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    size: PropTypes.any.isRequired,
    inProgress: PropTypes.bool.isRequired,
    usePreviousImage: PropTypes.string.isRequired
}

ImageEditor.defaultProps = {
    usePreviousImage: ''
}
export default ImageEditor;