import axios from 'axios';
import { getHeaders } from 'shared/http';
import { handleToken } from 'shared/settings/auth';

class InterfaceService {

    /**
     * 
     * Called this method after success response came from axios 
     * 
     * @param response 
     * @returns response
     */
    handleSuccess(response) {
        return response;
    };

    /**
     * 
     * Called when error response came from axios
     * 
     * @param error 
     * @returns return error. E.g {message: 'internal server error'}.
     */
    handleError = (error) => {
        const status = error.response ? error.response.status : null

        if (status === 401) {
            return handleToken().then(res => {
                const originalRequestConfig = error.config;
                originalRequestConfig.headers = getHeaders()['headers'];
                return axios.request(originalRequestConfig);
            });
        }

        try {
            const return_data = {};
            if (error.response) {
                return_data['message'] = `${error.response.data.message ? error.response.data.message : error.response.status}`;
            } else if (error.request) {
                const message = error?.message ?
                    error.message : error?.request?.statusText ?
                        error.request.statusText : error?.request?.responseTex ?
                            error.request.responseTex : "Network Error";
                return_data['message'] = message;
            } else {
                return_data['message'] = `${error.message}`;
            }
            if (error.response?.status) {
                return_data['status'] = error.response.status;
            }
            return Promise.reject(return_data);
        } catch (error) {
            return Promise.reject(error);
        }

    }

    /**
     * 
     * @returns Interface service
     */
    getService() {
        let service = axios.create();
        service.interceptors.response.use(this.handleSuccess, this.handleError);
        return service;
    }
}
// eslint-disable-next-line 
export default new InterfaceService();