export const publicUrl = process.env.PUBLIC_URL;

/** Login page images */
export const loginPageHeaderImage = publicUrl + "/static/logo/cohora_login.svg";

/** Info icon */
export const infoIcon = publicUrl + "/static/images/info-icon.svg";

/** Builder start gif */
export const exampleGif = publicUrl + "/static/help/builder_example.gif";

/** Header Icons */
export const Logo = publicUrl + "/static/icons/Header/logo.png";
export const LogoMobile = publicUrl + "/static/icons/Header/logo-mobile.png";
export const Logout = publicUrl + "/static/icons/Header/power.png";

/** Template */
export const TourBG = publicUrl + "/static/images/template/tour.png";