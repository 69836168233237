import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "shared/withRouter";

import RemovePreLoader from "shared/utils/PreLoader";
import './PageNotFound.scss';

class PageNotFound extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        RemovePreLoader();
    }

    backToHome = () => {
        this.props.router.navigate('/templates/Website/templates');
    }

    render() {
        return (
            <div className="page-404 h-100">
                <div className="d-flex align-items-center justify-content-center h-100">
                    <div className="text-center">
                        <div>
                            <h2 className="oops">Oops!</h2>
                        </div>
                        <div>
                            <p>Sorry, we can't find the page you're looking for.</p>
                        </div>
                        <div>
                            <button className="back-to-home" onClick={this.backToHome}>Back To Home</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(state => state)(withRouter(PageNotFound));