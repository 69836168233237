import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TemplateService } from "shared/services";
import { withRouter } from "shared/withRouter";
import { ArrayValueReorder } from "shared/utils/sorting";
import store from "features/store";
import TemplateCloneModal from "./TemplateCloneModal";
import { Button, Modal, Spinner } from "react-bootstrap";

import "./Templates.scss";

class Templates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoading: true,
      predefinedTemplates: [],
      currentClone: null,
      askConfirmForDelete: false,
      deleteTemplate: null,
      deleteInProgress: false,
    };
  }

  componentDidMount() {
    this.InitialData();
  }

  InitialData = () => {
    if (this.props.link) {
      TemplateService.getTemplateList({ templateType: this.props.link })
        .then((res) => {
          let data = res.data;
          if (data.length > 0) {
            data = ArrayValueReorder(data, "createdAt", false);
          }
          const predefinedTemplates = data.filter((x) => x.predefined);
          this.setState(
            {
              predefinedTemplates,
              pageLoading: false,
            },
            () => {
              store.dispatch({
                type: "TEMPLATE_LIST_SCREEN_HEIGHT",
                payload: false,
              });
            }
          );
        })
        .catch((error) => {
          store.dispatch({
            type: "TEMPLATE_LIST_SCREEN_HEIGHT",
            payload: false,
          });
          this.setState({
            pageLoading: false,
          });
        });
    } else {
      store.dispatch({ type: "TEMPLATE_LIST_SCREEN_HEIGHT", payload: false });
      this.setState({
        pageLoading: false,
      });
    }
  };

  templateClone = (template) => {
    this.setState({
      currentClone: template,
    });
  };

  cleanCloneTemplate = () => {
    this.setState({
      currentClone: null,
    });
  };

  editTemplate = (template) => {
    this.props.router.navigate(
      `/template/${this.props.pageIs.toLowerCase()}/edit/${template.id}`,
      { state: { tab: this.props.router.params.tab } }
    );
  };

  previewTemplate = (template) => {
    window
      .open(
        `/template/${this.props.pageIs.toLowerCase()}/preview/${template.id}`,
        "_blank"
      )
      .focus();
  };

  askForDelete = (template) => {
    if (this.props.user.tenantAdmin) {
      this.setState({
        askConfirmForDelete: true,
        deleteTemplate: template,
      });
    }
  };

  closeDeleteModal = () => {
    this.setState({
      askConfirmForDelete: false,
      deleteInProgress: false,
    });
  };

  deleteTemplate = () => {
    this.setState(
      {
        deleteInProgress: true,
      },
      () => {
        TemplateService.deleteTemplate(this.state.deleteTemplate.id)
          .then((res) => {
            store.dispatch({
              type: "TEMPLATE_LIST_SCREEN_HEIGHT",
              payload: true,
            });
            this.closeDeleteModal();
            this.setState(
              {
                pageLoading: true,
              },
              () => {
                this.InitialData();
              }
            );
          })
          .catch((error) => {
            this.setState({
              deleteInProgress: false,
            });
          });
      }
    );
  };

  render() {
    return (
      <div className="templates-list h-100">
        {this.state.pageLoading ? (
          <div className={`page-loading`}>
            <div className="d-flex align-items-center justify-content-center h-100">
              <div
                className="spinner-border text-danger"
                style={{ width: "3rem", height: "3rem" }}
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          <div>
            {/* <div className="filter-section mb-4 mt-3">
                                <div className="d-md-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center">
                                        <div className="text-muted me-2">Categories</div>
                                        <select className="form-select" aria-label="Default select example" defaultValue="All">
                                            <option>All</option>
                                        </select>
                                    </div>
                                    <div>
                                        <input className="form-control" type="text" placeholder="Search" aria-label="default input example"></input>
                                    </div>
                                </div>
                            </div> */}
            <div className="templates-section mt-4">
              {this.state.predefinedTemplates.length === 0 ? (
                <div className="zero-preview-template mt-3 d-flex align-items-center justify-content-center preview-template-joy">
                  No Data to Show
                </div>
              ) : (
                <div className={`preview-template-joy`}>
                  <div className="d-flex flex-wrap align-items-center justify-content-start">
                    {this.state.predefinedTemplates.map(
                      (pre_template, index) => (
                        <div
                          className="preview-template-card card"
                          key={`predefinedTemplates_${index}`}
                        >
                          <div
                            className={`img-hv-container ${
                              this.state.run ? "tour-preview" : ""
                            }`}
                          >
                            <img
                              src={pre_template.preview.url}
                              alt="pre-template"
                              className="image"
                            ></img>
                            <div className="buttons w-100 d-flex flex-column align-items-center justify-content-center">
                              <button
                                className="mb-2 predefined-template-copy"
                                onClick={() => this.templateClone(pre_template)}
                              >
                                Clone
                              </button>
                              {this.props.user.tenantAdmin && (
                                <button
                                  className="mb-2 predefined-template-edit"
                                  onClick={() =>
                                    this.editTemplate(pre_template)
                                  }
                                >
                                  Edit
                                </button>
                              )}
                              <button
                                className="mb-2 predefined-template-preview"
                                onClick={() =>
                                  this.previewTemplate(pre_template)
                                }
                              >
                                Preview
                              </button>
                              {this.props.user.tenantAdmin && (
                                <button
                                  className="mb-2 predefined-template-delete"
                                  onClick={() =>
                                    this.askForDelete(pre_template)
                                  }
                                >
                                  Delete
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {/* Delete modal */}
        <Modal
          show={this.state.askConfirmForDelete}
          onHide={this.closeDeleteModal}
          centered
        >
          <Modal.Body>
            <div className="template-delete-confirm text-center py-2">
              <div>
                <span className="warning-icon">&#9432;</span>
              </div>
              <div className="h4 mb-4">
                Are you sure you want to remove this template?
              </div>
              <div className="buttons-container text-center">
                <Button variant="secondary" onClick={this.closeDeleteModal}>
                  Cancel
                </Button>
                {this.state.deleteInProgress ? (
                  <Button variant="primary" disabled={true}>
                    <Spinner
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                      size="sm"
                    />
                  </Button>
                ) : (
                  <Button variant="primary" onClick={this.deleteTemplate}>
                    OK
                  </Button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Clone modal */}
        <TemplateCloneModal
          template={this.state.currentClone}
          cleanCloneTemplate={this.cleanCloneTemplate}
        ></TemplateCloneModal>
      </div>
    );
  }
}

Templates.propTypes = {
  pageIs: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default connect((state) => state)(withRouter(Templates));
