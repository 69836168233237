import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";

export const withRouter = (Component) => {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let _useNavigate = useNavigate();
        let params = useParams();

        function navigate(url, extra = { replace: false }) {
            _useNavigate(url, { ...extra });
        }

        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }
    return ComponentWithRouterProp;
}