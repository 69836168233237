import moment from "moment";

/**
 * formats date time to U.S standard
 *
 * @param {string} dateTime the date to be formatted
 * 
 * @param {boolean} timeOnly (optional) defaults to false, when provided true will return time only
 * 
 * @returns formatted date
 *  
 **/
 export const dateTimeFormat = (dateTime, timeOnly = false) => {
    const formattedDate = moment.utc(dateTime).local();
    let returnValue = formattedDate.format('l');
    if (timeOnly) {
        returnValue = formattedDate.format('LTS')
    }

    return returnValue;
};