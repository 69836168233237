// library imports
import Axios from 'axios';

// project imports
import { ACCESS_TOKEN, REFRESH_TOKEN, setToken, unsetToken, getToken, isTokenExpired, EXPIRES_IN } from './token';
import { getHeaders, getBaseUrl } from 'shared/http';
import { refreshToken, userDetail } from 'shared/settings/apiConfig';
import store from 'features/store';
import { tempUserExclude } from './tempUserExclude';

/* sets token for the newly logged in user */
export async function login(token) {
    // sets the user token in local storage
    setToken(token);

    // call the user api to set user detail in app
    await setUser();
}

/* removes user token on logout or expiry */
export function logout() {
    // unset user from app
    store.dispatch({ type: 'SET_USER', payload: {} });

    // clears the new access token get call interval
    if (store.getState()['refreshInterval']) {
        clearInterval(store.getState()['refreshInterval']);
    }

    // clears token details from local storage
    unsetToken();
}


/* set user details to global state from token payload */
export async function setUser() {
    try {
        await Axios.get(getBaseUrl(`${userDetail}`), getHeaders())
            .then(response => {
                setTokenInterval();
                
                /** ToDo: Remove this after user role */
                const user = response.data;
                const isAdmin = tempUserExclude.includes(response.data.email);
                if (isAdmin) {
                    user.tenantAdmin = false;
                }
                store.dispatch({
                    type: 'SET_USER',
                    payload: { ...user }
                });

                /** ToDo: Enable below after get user role */
                // store.dispatch({
                //     type: 'SET_USER',
                //     payload: { ...response.data }
                // });
                store.dispatch({ type: 'SET_SIGNING_FORM_LOADING', payload: false });
            }).catch(error => {
                logout(null, false);
            });
    } catch (error) {
        logout(null);
        store.dispatch({ type: 'SET_SIGNING_FORM_LOADING', payload: false });
    }
}

/* checks where user is authenticated by validating token */
export async function isAuthenticated() {
    if (!isTokenExpired()) {
        await tokenRefresh();
        // await getAppToken();
        await setUser();
    } else {
        if (getToken(REFRESH_TOKEN)) {
            await logout();
        }
    }
}

/* checks whether a valid token is available */
export function isAuthorized(logoutUser = false) {
    let userLogin = getToken(ACCESS_TOKEN) ? true : false;

    if (logoutUser && userLogin) {
        logout();
        userLogin = false;
    }

    return userLogin;
}

/* set interval to update access token */
async function setTokenInterval() {
    const expiresIn = getToken(EXPIRES_IN);
    const now = Date.now()
    if ((expiresIn - now) < 70000) {
        tokenRefresh().then(res => res).catch(error => error);
    } else {
        setTimeout(() => {
            setTokenInterval();
        }, 10000);
    }
}

/* api call to fetch new access token */
async function tokenRefresh() {
    const headers = {
        'Authorization': `Bearer ${getToken(REFRESH_TOKEN)}`,
        'Content-Type': 'application/json'
    };

    await Axios.post(getBaseUrl(refreshToken), JSON.stringify({ refreshToken: getToken(REFRESH_TOKEN) }), { headers: headers })
        .then(async response => {
            await setToken(response.data['accessToken']);
            return response;
        })
        .catch(async error => {
            await logout();
            store.dispatch({ type: 'NAVIGATE_TO_LOGIN', payload: true });
            return error;
        });
}

export async function handleToken() {
    return await tokenRefresh();
}

/**
 * Manual token refresh
 *  
 * @returns Promise
 */
export async function checkRefreshToken() {
    return await isAuthenticated();
}

/**
 * If session expired this will return true otherwise return false
 * 
 * @returns True or False
 */
export function isSessionExpired() {
    return isTokenExpired();
}