import store from "features/store";
import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { logout } from "shared/settings/auth";
import { withRouter } from "shared/withRouter";
import { Logo, Logout } from "shared/utils/images";
import './Header.scss';
import { TemplateService } from "shared/services";

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            applyLargeScreenSize: true,
            askConfirmForLogout: false,
            logo: '',
        }
    }

    componentDidMount() {
        this.checkCurrentPath();
        this.getClient()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.router.location.pathname !== this.props.router.location.pathname) {
            this.checkCurrentPath();
        }
    }

    getClient = () => {
        TemplateService.getCurrentTenant().then(res => {
            let data = res.data;
            // console.log("data", data);
            if (data?.logoUrl) {
                this.setState({
                    logo: data.logoUrl
                })
            } else {
                this.setState({
                    logo: Logo
                })
            }
        }).catch(error => {
            this.setState({
                logo: Logo
            })
        });
    }

    checkCurrentPath = () => {
       
        const isWebsite = this.getActivePage('website');
        const isCampaign = this.getActivePage('campaign');
        const isPopup = this.getActivePage('popup');
        const isNotification = this.getActivePage('notification');
        const isOffers = this.getActivePage('offers');
        const isContest = this.getActivePage('contest');
        const isRAF = this.getActivePage('referafriend');
        this.setState({
            activePage: isWebsite ? 'Website' : isCampaign ? 'Campaign' : isPopup ? 'Popup' : isOffers ? 'Offers':isNotification ? 'Notification' : isContest ? 'Contest': isRAF ? 'ReferAFriend' : 'Scratchcard',
            applyLargeScreenSize: !this.props.router.params.hasOwnProperty('templateId')
        });
    }

    getActivePage = (page) =>{
        const current_path = this.props.router.location.pathname.split('/');
        const _pages = current_path.map(page => page.toLowerCase());
        return _pages.includes(page)
    }

    /** Navigate to home page */
    homePage = (event) => {
        event.preventDefault();
        this.props.router.navigate('/templates/Website/templates');
    }

    toggleSideBar = () => {
        if (this.props.toggleSideBar) {
            this.props.toggleSideBar(true);
        }
    }


    /**
     * Navigate to child template list page 
     * @param { Event } event Click event - For disable default a tag click function.
     * @param {'Campaign' | 'Website' | 'Popup'} flag Page name
     */
    changePage = (event, flag) => {
        event.preventDefault();
        this.setPageHeight();
        this.props.router.navigate(`/templates/${flag}/templates`);
    }

    setPageHeight = () => {
        store.dispatch({ type: 'TEMPLATE_LIST_SCREEN_HEIGHT', payload: true });
    }

    askConfirmForLogout = () => {
        this.setState({
            askConfirmForLogout: true
        });
    }

    closeLogoutModal = () => {
        this.setState({
            askConfirmForLogout: false
        });
    }

    _logout = async () => {
        await logout();
        this.closeLogoutModal();
        this.props.router.navigate('/login');
    }

    render() {
        return (
            <div className="text-bg-white header-container">
                <div className="d-flex align-items-center justify-content-center">
                    <div className={`${this.state.applyLargeScreenSize ? 'large-screen-width' : 'w-100'}`}>
                        <div className="d-flex align-items-center justify-content-between">

                            {/* Left side section */}
                            <div className="left-section d-flex align-items-center justify-content-start">
                                <div className="d-flex align-items-center">
                                    <div className="px-2">
                                        {/* <a className="brand-name" href="/" onClick={this.homePage}>Cohora</a> */}
                                        <img src={this.state.logo} alt="brand-logo" className="ms-4 brand-logo d-none d-md-block clickable" onClick={this.homePage}></img>
                                        <img src={this.state.logo} alt="brand-logo-mobile" className="brand-logo-mobile ms-4 d-block d-md-none clickable" onClick={this.homePage}></img>
                                    </div>
                                </div>
                                <section className='pages-section d-flex align-items-end justify-content-center'>
                                    <div className='d-flex align-items-center px-3' style={{ height: '5rem'}}>
                                        <div className={`page py-4 px-4 ${this.state.activePage === 'Website' ? 'active' : ''}`}>
                                            <a href='/templates/Website/templates' onClick={(event) => this.changePage(event, 'Website')}>
                                                <div>Website</div>
                                            </a>
                                        </div>
                                        <div className={`page py-4 px-4 ${this.state.activePage === 'Campaign' ? 'active' : ''}`}>
                                            <a href='/templates/Campaign/templates' onClick={(event) => this.changePage(event, 'Campaign')}>
                                                <div>Campaign</div>
                                            </a>
                                        </div>
                                        <div className={`page py-4 px-4 ${this.state.activePage === 'Popup' ? 'active' : ''}`}>
                                            <a href='/templates/Popup/templates' onClick={(event) => this.changePage(event, 'Popup')}>
                                                <div>Popup</div>
                                            </a>
                                        </div>
                                        <div className={`page py-4 px-4 ${this.state.activePage === 'Offers' ? 'active' : ''}`}>
                                            <a href='/templates/Offers/templates' onClick={(event) => this.changePage(event, 'Offers')}>
                                                <div>Offers</div>
                                            </a>
                                        </div>
                                        <div className={`page py-4 px-4 ${this.state.activePage === 'Notification' ? 'active' : ''}`}>
                                            <a href='/templates/Notification/templates' onClick={(event) => this.changePage(event, 'Notification')}>
                                                <div>Notification</div>
                                            </a>
                                        </div>
                                        <div className={`page py-4 px-4 ${this.state.activePage === 'ReferAFriend' ? 'active' : ''}`}>
                                            <a href='/templates/ReferAFriend/templates' onClick={(event) => this.changePage(event, 'ReferAFriend')}>
                                                <div>Refer A Friend</div>
                                            </a>
                                        </div> 
                                         {/* <div className={`page py-4 px-4 ${this.state.activePage === 'Scratchcard' ? 'active' : ''}`}>
                                            <a href='/templates/Scratchcard/templates' onClick={(event) => this.changePage(event, 'Scratchcard')}>
                                                <div>Scratchcard</div>
                                            </a>
                                        </div> */}
                                        {/* <div className={`page py-4 px-4 ${this.state.activePage === 'Contest' ? 'active' : ''}`}>
                                            <a href='/templates/Contest/templates' onClick={(event) => this.changePage(event, 'Contest')}>
                                                <div>Contest</div>
                                            </a>
                                        </div> */}
                                    </div>
                                </section>
                            </div>

                            {/* Profile section */}
                            <div className="right-section d-flex align-items-center justify-content-end">
                                <div className="pe-4 d-flex align-items-center justify-content-center clickable" onClick={this.askConfirmForLogout}>
                                    <img src={Logout} alt="Logout" className="logout-button" />
                                    <span className="d-none d-md-block fw-semibold">&ensp;Logout</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Logout modal */}
                <Modal show={this.state.askConfirmForLogout} onHide={this.closeLogoutModal} centered>

                    <Modal.Body>
                        <div className="template-delete-confirm text-center py-2">
                            <div>
                                <span className="warning-icon">
                                    &#9432;
                                </span>
                            </div>
                            <div className="h4 mb-4">
                                Are you sure you want to logout?
                            </div>
                            <div className="buttons-container text-center">
                                <Button variant="secondary" onClick={this.closeLogoutModal}>
                                    Cancel
                                </Button>
                                <Button variant="primary" onClick={this._logout}>
                                    Logout
                                </Button>
                            </div>
                        </div>
                    </Modal.Body>

                </Modal>
            </div>
        );
    }
}

export default connect(state => state)(withRouter(Header));