import axios from "axios";
import { getBaseUrl, getHeaders } from "shared/http";
import { templateCRUD } from "shared/settings/apiConfig";

const storageManager = (projectID, onDownloadProgressCallBack) => {
    return {
        type: 'remote', // Type of the storage, available: 'local' | 'remote'
        autosave: false, // Store data automatically
        options: {
            local: { // Options for the `local` type
                key: 'gjsProject', // The key for the local storage
            },
            remote: {
                urlLoad: `${getBaseUrl(templateCRUD)}/${projectID}`,
                urlStore: `${getBaseUrl(templateCRUD)}/${projectID}`,
                headers: getHeaders().headers,
                onStore: (data, editor) => {
                    return { id: projectID };
                },
                onLoad: async result => {
                    try {
                        if (result.hasOwnProperty('componentFile')) {
                            const S3Data = await axios.get(result.componentFile.url, {
                                onDownloadProgress: async (progressEvent) => {
                                    const total = parseFloat(progressEvent.total)
                                    const current = progressEvent.loaded
                                    let percentCompleted = Math.floor(current / total * 100)
                                    onDownloadProgressCallBack(percentCompleted);
                                }
                            });
                            if (S3Data.data) {
                                return S3Data.data;
                            }
                        }
                        return {};
                    } catch (error) {
                        return {};
                    }
                }
            }
        }
    }
}

// const storageManager = false;

export default storageManager;