/* eslint-disable no-undef */
/* eslint-disable import/no-anonymous-default-export */
import constants from '../constants';

export default (DomComponents, config = {}) => {
    const _defaultType = DomComponents.getType('default');
    const _defaultModel = _defaultType.model;
    const _defaultView = _defaultType.view;

    DomComponents.addType(constants.containerName, {
        model: {
            defaults: {
                ..._defaultModel.prototype.defaults,
                name: 'Swiper',

                // Properties
                initialSlide: '0',
                speed: 300,
                slidesPerView: 1,
                spaceBetween: '0',
                slidesPerGroup: 1,
                slidesOffsetBefore: '0',
                slidesOffsetAfter: '0',
                direction: 'horizontal',
                effect: 'slide',
                autoHeight: false,
                watchOverflow: true,
                centeredSlides: false,
                loop: false,
                pagination: 'bullets',
                scrollbar: false,

                // Autoplay
                autoplay: false,
                autoplayDelay: 3000,
                autoplayStopOnLastSlide: false,
                autoplayDisableOnInteraction: true,
                autoplayReverseDirection: false,
                autoplayWaitForTransition: true,

                // Small
                smallSlidesPerView: 1,
                smallSpaceBetween: '50',
                smallSlidesPerGroup: 1,

                // Medium
                mediumSlidesPerView: 2,
                mediumSpaceBetween: '50',
                mediumSlidesPerGroup: 1,

                // Large
                largeSlidesPerView: 5,
                largeSpaceBetween: '50',
                largeSlidesPerGroup: 1,

                traits: [
                    // {
                    //     type: 'number',
                    //     label: 'Initial Slide',
                    //     name: 'initialSlide',
                    //     changeProp: 1,
                    // },
                    // {
                    //     type: 'number',
                    //     label: 'Speed',
                    //     name: 'speed',
                    //     changeProp: 1,
                    // },
                    // {
                    //     type: 'number',
                    //     label: 'Slides Per View',
                    //     name: 'slidesPerView',
                    //     changeProp: 1,
                    // },
                    // {
                    //     type: 'number',
                    //     label: 'Space Between',
                    //     name: 'spaceBetween',
                    //     changeProp: 1,
                    // },
                    // {
                    //     type: 'number',
                    //     label: 'Slides Per Group',
                    //     name: 'slidesPerGroup',
                    //     changeProp: 1,
                    // },
                    // {
                    //     type: 'number',
                    //     label: 'Slides Offset Before',
                    //     name: 'slidesOffsetBefore',
                    //     changeProp: 1,
                    // },
                    // {
                    //     type: 'number',
                    //     label: 'Slides Offset After',
                    //     name: 'slidesOffsetAfter',
                    //     changeProp: 1,
                    // },
                    {
                        type: 'checkbox',
                        label: 'Autoplay',
                        name: 'autoplay',
                        changeProp: 1,
                    },
                    {
                        type: 'number',
                        label: 'Autoplay Delay',
                        name: 'autoplayDelay',
                        changeProp: 1,
                    },
                    {
                        type: 'checkbox',
                        label: 'Autoplay stop on Last slide',
                        name: 'autoplayStopOnLastSlide',
                        changeProp: 1,
                    },
                    {
                        type: 'checkbox',
                        label: 'Autoplay disable on interaction',
                        name: 'autoplayDisableOnInteraction',
                        changeProp: 1,
                    },
                    {
                        type: 'checkbox',
                        label: 'Autoplay reverse direction',
                        name: 'autoplayReverseDirection',
                        changeProp: 1,
                    },
                    {
                        type: 'checkbox',
                        label: 'Autoplay wait for transition',
                        name: 'autoplayWaitForTransition',
                        changeProp: 1,
                    },
                    // {
                    //     type: 'select',
                    //     label: 'Direction',
                    //     name: 'direction',
                    //     changeProp: 1,
                    //     options: [
                    //         'horizontal',
                    //         'vertical',
                    //     ]
                    // },
                    // {
                    //     type: 'select',
                    //     label: 'Transition effect',
                    //     name: 'effect',
                    //     changeProp: 1,
                    //     options: [
                    //         'slide',
                    //         'fade',
                    //         'cube',
                    //         'coverflow',
                    //         'flip',
                    //     ]
                    // },
                    // {
                    //     type: 'checkbox',
                    //     label: 'Auto Height',
                    //     name: 'autoHeight',
                    //     changeProp: 1,
                    // },
                    // {
                    //     type: 'checkbox',
                    //     label: 'Watch Overflow',
                    //     name: 'watchOverflow',
                    //     changeProp: 1,
                    // },
                    // {
                    //     type: 'checkbox',
                    //     label: 'Centered Slides',
                    //     name: 'centeredSlides',
                    //     changeProp: 1,
                    // },
                    // {
                    //     type: 'checkbox',
                    //     label: 'Loop',
                    //     name: 'loop',
                    //     changeProp: 1,
                    // },
                    // {
                    //     type: 'checkbox',
                    //     label: 'Scrollbar',
                    //     name: 'scrollbar',
                    //     changeProp: 1,
                    // },
                    {
                        type: 'select',
                        label: 'Pagination',
                        name: 'pagination',
                        changeProp: 1,
                        options: [
                            'none',
                            'bullets',
                            'fraction',
                            // 'progressbar',
                        ]
                    },

                    // Small
                    {
                        type: 'number',
                        label: 'Small Slides Per View',
                        name: 'smallSlidesPerView',
                        changeProp: 1,
                    },
                    {
                        type: 'number',
                        label: 'Small Space Between',
                        name: 'smallSpaceBetween',
                        changeProp: 1,
                    },
                    // {
                    //     type: 'number',
                    //     label: 'Small Slider Per Group',
                    //     name: 'smallSlidesPerGroup',
                    //     changeProp: 1,
                    // },

                    // Medium
                    {
                        type: 'number',
                        label: 'Medium Slides Per View',
                        name: 'mediumSlidesPerView',
                        changeProp: 1,
                    },
                    {
                        type: 'number',
                        label: 'Medium Space Between',
                        name: 'mediumSpaceBetween',
                        changeProp: 1,
                    },
                    // {
                    //     type: 'number',
                    //     label: 'Medium Slider Per Group',
                    //     name: 'mediumSlidesPerGroup',
                    //     changeProp: 1,
                    // },

                    // Large
                    {
                        type: 'number',
                        label: 'Large Slides Per View',
                        name: 'largeSlidesPerView',
                        changeProp: 1,
                    },
                    {
                        type: 'number',
                        label: 'Large Space Between',
                        name: 'largeSpaceBetween',
                        changeProp: 1,
                    },
                    // {
                    //     type: 'number',
                    //     label: 'Large Slider Per Group',
                    //     name: 'largeSlidesPerGroup',
                    //     changeProp: 1,
                    // },
                ],

                droppable: `${constants.wrapperSelector}, ${constants.prevSelector}, ${constants.nextSelector}, ${constants.scrollbarSelector}, ${constants.paginationSelector}}`,

                draggable: true,

                editable: true,
                style: {
                    width: "100%",
                    // height: "100%",
                    "min-height": "100px"
                },

                'script-deps': config.script,
                'style-deps': config.style,
                'class-container': config.classContainer,
                'class-wrapper': config.classWrapper,
                'class-prev': config.classPrev,
                'class-next': config.classNext,
                'class-pagination': config.classPagination,
                'class-scrollbar': config.classScrollbar,

                script: function () {
                    const el = this;
                    const scriptDeps = '{[ script-deps ]}';
                    const styleDeps = '{[ style-deps ]}';
                    const falsies = ['0', 'false', 'none'];
                    const truthies = ['1', 'true'];
                    const elId = el.getAttribute('id');
                    el.classList.add('swiper');

                    const options = {
                        slidesPerView: 1,
                        spaceBetween: 10,
                        navigation: {
                            nextEl: `#${elId} .swiper-button-next`,
                            prevEl: `#${elId} .swiper-button-prev`,
                        },
                        breakpoints: {
                            640: {
                                slidesPerView: parseInt('{[ smallSlidesPerView ]}', 10),
                                spaceBetween: parseInt('{[ smallSpaceBetween ]}', 10),
                            },
                            768: {
                                slidesPerView: parseInt('{[ mediumSlidesPerView ]}', 10),
                                spaceBetween: parseInt('{[ mediumSpaceBetween ]}', 10),
                            },
                            1024: {
                                slidesPerView: parseInt('{[ largeSlidesPerView ]}', 10),
                                spaceBetween: parseInt('{[ largeSpaceBetween ]}', 10),
                            },
                        },
                    };

                    if (!falsies.includes('{[ pagination ]}')) {
                        options.pagination = {
                            el: `#${elId} .swiper-pagination`,
                            type: '{[ pagination ]}',
                            clickable: true
                        };
                    }

                    if (truthies.includes('{[ autoplay ]}')) {
                        options.autoplay = {
                            delay: '{[ autoplayDelay ]}',
                            stopOnLastSlide: '{[ autoplayStopOnLastSlide ]}',
                            disableOnInteraction: '{[ autoplayDisableOnInteraction ]}',
                            reverseDirection: '{[ autoplayReverseDirection ]}',
                            waitForTransition: '{[ autoplayWaitForTransition ]}',
                        };
                    }

                    if (isNaN('{[ scrollbar ]}')) {
                        options.scrollbar = {
                            el: `#${elId} .swiper-scrollbar`,
                            draggable: true,
                        };
                    }

                    const initSlider = function () {
                        new Swiper(el, options);
                        // console.log(sliderSwiper);
                    };

                    if (typeof Swiper == 'undefined') {
                        // Load the style tag
                        const _style = document.createElement('link');
                        _style.rel = 'stylesheet';
                        _style.href = styleDeps;
                        document.head.appendChild(_style);

                        // Load the script tag
                        const _script = document.createElement('script');
                        _script.src = scriptDeps;
                        _script.onload = initSlider;
                        document.head.appendChild(_script);
                    } else {
                        initSlider();
                    }
                },
                ...config.sliderProps
            }
        },
        isComponent: (el) => {
            if (el.hasAttribute && el.hasAttribute(constants.containerId)) {
                return { type: constants.containerName };
            }
        },
        view: _defaultView.extend({
            init() {
                const props = [
                    'initialSlide',
                    'speed',
                    'slidesPerView',
                    'spaceBetween',
                    'slidesPerGroup',
                    'slidesOffsetBefore',
                    'slidesOffsetAfter',
                    'direction',
                    'effect',
                    'autoHeight',
                    'watchOverflow',
                    'centeredSlides',
                    'loop',
                    'pagination',
                    'scrollbar',
                    'autoplay',
                    'autoplayDelay',
                    'autoplayStopOnLastSlide',
                    'autoplayDisableOnInteraction',
                    'autoplayReverseDirection',
                    'autoplayWaitForTransition',

                    // Small
                    'smallSlidesPerView',
                    'smallSpaceBetween',
                    'smallSlidesPerGroup',

                    // Medium
                    'mediumSlidesPerView',
                    'mediumSpaceBetween',
                    'mediumSlidesPerGroup',

                    // Large
                    'largeSlidesPerView',
                    'largeSpaceBetween',
                    'largeSlidesPerGroup',
                ];

                const reactTo = props.map(prop => `change:${prop}`).join(' ');
                this.listenTo(this.model, reactTo, this.render);
                const comps = this.model.components();

                // Add a basic template if it's not yet initialized
                if (!comps.length) {
                    comps.add(`
                            <div data-gjs-type="${constants.wrapperName}">${config.slideEls}</div>
                            <div data-gjs-type="${constants.prevName}"></div>
                            <div data-gjs-type="${constants.nextName}"></div>
                            <div data-gjs-type="${constants.paginationName}"></div>
                          `
                    );
                }
            },
        }),
    });
}