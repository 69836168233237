import { getBaseUrl, getHeaders } from 'shared/http';
import { fileUpload, signedUrl, templateCRUD, eventType, eventCategories, currentTenant } from 'shared/settings/apiConfig';
import InterfaceService from './InterfaceService';

class TemplateService {
    constructor() {
        this.service = InterfaceService.getService();
    }

    getCurrentTenant() {
        return this.service.get(getBaseUrl(currentTenant), getHeaders());
    }

    getPresignedUrl(data) {
        return this.service.get(getBaseUrl(signedUrl, data), getHeaders());
    }

    getAssetData(data, assetId) {
        return this.service.post(`${getBaseUrl(signedUrl)}/${assetId}/asset`, JSON.stringify(data), getHeaders());
    }

    createNewTemplate = (data) => {
        return this.service.post(getBaseUrl(templateCRUD), JSON.stringify(data), getHeaders());
    }

    updateTemplate = (data, templateId) => {
        return this.service.put(getBaseUrl(`${templateCRUD}/${templateId}`), JSON.stringify(data), getHeaders());
    }

    retrieveTemplate = (templateId) => {
        return this.service.get(`${getBaseUrl(templateCRUD)}/${templateId}`, getHeaders());
    }

    getTemplateList(prams) {
        return this.service.get(`${getBaseUrl(templateCRUD, prams)}`, getHeaders());
    }

    uploadPredefineFile(formData, flag = 'TEMPLATE') {
        return this.service.post(getBaseUrl(`${fileUpload}${flag}`), formData, getHeaders(true, 'file'));
    }

    deleteTemplate(templateId) {
        return this.service.delete(`${getBaseUrl(templateCRUD)}/${templateId}`, getHeaders());
    }
    
    cloudUpload(url, data, callBack) {
        return this.service.put(url, data, { headers: getHeaders(false, 'multipart/form-data').headers, onUploadProgress: callBack });
    }

    activateTemplate(data, templateId) {
        return this.service.post(`${getBaseUrl(templateCRUD)}/${templateId}/activate`, JSON.stringify(data), getHeaders());
    }

    getEventList(prams) {
        return this.service.get(`${getBaseUrl(eventType, '')}/${prams}`, getHeaders());
    }

    getEventCategories() {
        return this.service.get(`${getBaseUrl(eventCategories, '')}`, getHeaders());
    }
}
// eslint-disable-next-line 
export default new TemplateService();