/** React library */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

/** Project library */
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';

/** React bootstrap */
import { Row, Col, ButtonGroup, Button, Spinner } from 'react-bootstrap';

/** React image crop */
import ReactCrop from 'react-image-crop';

/** React icons */
import { BsZoomIn, BsZoomOut } from 'react-icons/bs';
import { IoRefreshOutline } from 'react-icons/io5';

/** Style */
import 'react-image-crop/dist/ReactCrop.css';
import './index.scss';

function ImageCropper(props) {
    const cropper = props.config.crop ? props.config.crop : undefined;
    const [file, setFile] = useState(props.file);
    const previewCanvasRef = useRef(null);
    const imgRef = useRef(null);
    const [crop, setCrop] = useState(cropper);
    const [completedCrop, setCompletedCrop] = useState();
    const [isCropSaving, setCropSaving] = useState(false);
    const [scale, setScale] = useState(1);
    const [aspect, setAspect] = useState(16 / 9);
    const [rotate, setRotate] = useState(props.config.rotate ?? 0);

    const reset = () => {
        setCrop(cropper);
        setCropSaving(false);
        setScale(props.config.scale ?? 1);
        setRotate(props.config.rotate ?? 0);
        setAspect(16 / 9);
    }

    const setCanvasFile = async () => {
        setCropSaving(true);
        const blob = await canvasPreview(
            imgRef.current,
            completedCrop ?? crop,
            scale,
            rotate,
            props.file.name
        )
        setFile(blob);
        props.onCropComplete(blob, { crop, scale, rotate });
        setCropSaving(false);
    }

    const onImageLoad = (e) => {
        // if (aspect) {
        //     const { width, height } = e.currentTarget
        //     setCrop(startAspectCrop(width, height, aspect))
        // }
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                previewCanvasRef.current
            ) {
                // We use canvasPreview as it's much faster than imgPreview.
                canvasPreview(
                    imgRef.current,
                    previewCanvasRef.current,
                    completedCrop,
                    scale,
                    rotate,
                    props.file.name
                )
            }
        },
        100,
        [completedCrop, scale, rotate],
    )

    return (
        <div className="cropper-main position-relative">
            <Row>
                <Col>
                    {file && (
                        <ReactCrop
                            crop={crop}
                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onComplete={(c) => setCompletedCrop(c)}
                            aspect={aspect}
                            locked={props.isLocked}
                        >
                            <img
                                ref={imgRef}
                                alt="Crop me"
                                src={props.imgSrc}
                                style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>
                    )}

                </Col>
                <Col md={3}>
                    <div className='mb-3'>
                        <div className='text-center'>Zoom</div>
                        <ButtonGroup className='d-flex'>
                            <Button title='Zoom In' variant='outline-info' onClick={() => setScale(Number(scale + .1))}>
                                <BsZoomIn />
                            </Button>
                            <Button title='Zoom Out' variant='outline-info' onClick={() => setScale(Number(scale - .1))}>
                                <BsZoomOut />
                            </Button>
                            <Button title='Zoom Reset' variant='outline-info' onClick={() => setScale(1)}>
                                <IoRefreshOutline />
                            </Button>
                        </ButtonGroup>
                    </div>
                    <div className='text-center'>
                        <Button size="sm" variant='outline-info' onClick={e => reset()}>Reset</Button><br />
                        <Button size="sm" className='mt-2' variant='outline-info' onClick={e => setCanvasFile()}>
                            {
                                isCropSaving ?
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> :
                                    "Crop Complete"
                            }
                        </Button>
                    </div>
                </Col>
            </Row>
            {/* <div className='position-absolute d-none'>
                {!!completedCrop && (
                    <canvas
                        ref={previewCanvasRef}
                        style={{
                            border: '1px solid black',
                            objectFit: 'contain',
                            width: completedCrop.width,
                            height: completedCrop.height,
                        }}
                    />
                )}
            </div> */}
        </div>
    )
}

ImageCropper.propTypes = {
    onCropComplete: PropTypes.func.isRequired,
    isLocked: PropTypes.bool.isRequired,
    config: PropTypes.object.isRequired,
    file: PropTypes.any.isRequired,
    imgSrc: PropTypes.any.isRequired
}

ImageCropper.defaultProps = {
    isLocked: true
}

export default ImageCropper;