import { Button, Modal } from "react-bootstrap";

export const ChoseEmailOrPageModal = ({
  show,
  onHide = () => {},
  onPageClick = () => {},
  onEmailClick = () => {},
}) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Select template type</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex gap-3">
        <Button
          onClick={() => {
            onPageClick();
            onHide();
          }}
        >
          Page
        </Button>
        <Button
          onClick={() => {
            onEmailClick();
            onHide();
          }}
        >
          Email
        </Button>
      </Modal.Body>
    </Modal>
  );
};
