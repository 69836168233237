import { Component } from "react";
import { Alert, Button, Card, FloatingLabel, Form, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { AuthService } from "shared/services";
import { Validators } from "shared/validators/Validators";
import { withRouter } from "shared/withRouter";
import { loginPageHeaderImage } from "shared/utils/images";
import RemovePreLoader from "shared/utils/PreLoader";
import { CiLogin } from 'react-icons/ci';

import './Login.scss';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // form
            formValid: false,
            formControls: {
                email: { value: '', valid: false, error: '', validators: [Validators.required(), Validators.maxLength(60)] },
                password: { value: '', valid: false, error: '', validators: [Validators.required()] }
            },
            logInError: false,
            apiLoading: false,
            validated: false,
            inValidData: false,
        }
    }

    componentDidMount() {
        RemovePreLoader();
    }

    /* checks whether the input is valid or not */
    onInputValidation = (event, formName) => {
        let controlError = '';

        /* checks all the validators for the control */
        for (let validator of this.state[formName][event.target.name].validators) {
            controlError = validator(event.target.value);
            if (controlError) break;
        }

        /* sets the state of the control and form validity */
        this.setState({
            [formName]: {
                ...this.state[formName],
                [event.target.name]: {
                    ...this.state[formName][event.target.name],
                    value: event.target.value,
                    valid: !Boolean(controlError),
                    error: controlError
                }
            },
            inValidData: false
        },
            () => {
                /* check form validity */
                if (controlError) {
                    this.setState({ formValid: false });
                }
                else {
                    let formValid = true;
                    for (let control of Object.keys(this.state[formName])) {
                        if (!this.state[formName][control].valid) {
                            formValid = false;
                            break;
                        }
                    }
                    this.setState({ formValid: formValid });
                }
            });
    }

    submit = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setState({ validated: true });
        if (this.state.formValid) {
            this.setState({ logInError: false, apiLoading: true }, () => {
                const data = { "login": this.state.formControls.email.value, "password": this.state.formControls.password.value };
                AuthService._login(data).then(res => {
                    this.setState({ apiLoading: false })
                    this.props.router.navigate('/templates/Website/templates');
                }).catch(error => {
                    this.setState({ logInError: true, apiLoading: false });
                });
            });
        } else {
            this.setState({
                inValidData: true
            });
        }
    }

    render() {
        return (
            <div className="h-100 w-100 bg-light login-container">
                <div className="d-flex align-items-center justify-content-center h-100">
                    <Card className="p-3">
                        <Card.Title className="pt-3 pb-2 text-center">
                            <img src={loginPageHeaderImage} alt="logo" style={{ height: '1.25rem' }} />
                        </Card.Title>
                        <Card.Body>
                            {
                                this.state.logInError ?
                                    <Alert variant="danger" className="text-center">Invalid username or password</Alert>
                                    : null
                            }
                            {
                                this.state.inValidData ?
                                    <Alert variant="danger" className="text-center">Please enter username and password</Alert>
                                    : null
                            }
                            <Form noValidate onSubmit={this.submit}>
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Email"
                                    className="mb-3"
                                >
                                    <Form.Control type="email" name="email" placeholder="name@example.com" required value={this.state.formControls.email.value} onChange={(event) => this.onInputValidation(event, 'formControls')} />
                                    <div className="text mt-1">
                                        {
                                            this.state.formControls.email.error ? <small className="text-danger">{this.state.formControls.email.error}</small> : null
                                        }
                                    </div>
                                </FloatingLabel>
                                <FloatingLabel controlId="floatingPassword" label="Password">
                                    <Form.Control type="password" name="password" placeholder="Password" required value={this.state.formControls.password.value} onChange={(event) => this.onInputValidation(event, 'formControls')} />
                                    <div className="text mt-1">
                                        {
                                            this.state.formControls.password.error ? <small className="text-danger">{this.state.formControls.password.error}</small> : null
                                        }
                                    </div>
                                </FloatingLabel>
                                <div className="d-flex align-items-center justify-content-center mt-3">
                                    {
                                        this.state.apiLoading ?
                                            <Button variant="success" className="w-100 rounded-pill" type="button" disabled={true}>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            </Button> :
                                            <Button variant="success" className="w-100 rounded-pill" type="submit">
                                                <CiLogin className="h4 mb-0" /> Login
                                            </Button>
                                    }
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        );
    }
}

export default connect(state => state)(withRouter(Login));