/** React library */
import { Component } from 'react';

/** React redux */
import { connect } from 'react-redux';

/** Project library */
import { withRouter } from 'shared/withRouter';
import { Templates, SavedTemplates, NotificationTemplates } from './TemplateGallery';


/** Style */
import './Campaign.scss';
import constant from 'shared/utils/constant';

class ReferAFriend extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: ''
        }
    }
    componentDidMount() {
        console.log('ReferAFriend loaded');
        this.updateActivePage();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.router.location.pathname !== this.props.router.location.pathname) {
            this.updateActivePage();
        }
    }

    updateActivePage = () => {
        const currentTab = this.props.router.params.tab;
        this.setState({
            currentTab
        });
    }

    render() {
        return (
            <div className='campaign-container h-100'>
                {
                    this.state.currentTab ?
                        this.state.currentTab === 'templates' ?
                            <Templates link={constant.REFERAFRIEND} pageIs="ReferAFriend" /> :
                            this.state.currentTab === 'referafriend-templates' ?
                                <NotificationTemplates link={constant.NOTIFICATION} pageIs="ReferAFriend" /> :
                                <SavedTemplates link={constant.REFERAFRIEND} pageIs="ReferAFriend" />

                        : null
                }
            </div>
        );
    }
}

export default connect(state => state)(withRouter(ReferAFriend));