import { Component } from "react";
import { Outlet } from "react-router-dom";
import { connect } from "react-redux";
import Header from "./Header";
import RemovePreLoader from "shared/utils/PreLoader";
import "./AppLayout.scss";
import store from "features/store";
import { withRouter } from "shared/withRouter";

class AppLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSideBar: false,
    };
  }

  componentDidMount() {
    RemovePreLoader();
  }

  componentDidUpdate(prevProp) {
    if (
      prevProp.navigateToLogin !== this.props.navigateToLogin &&
      this.props.navigateToLogin
    ) {
      store.dispatch({ type: "NAVIGATE_TO_LOGIN", payload: false });
      this.props.router.navigate("/login");
    }
  }

  toggleSideBar = (event) => {
    this.setState({
      showSideBar: event,
    });
  };

  render() {
    return (
      <div className="app-layout h-100 overflow-auto">
        {this.props.showHeader ? (
          <div className="header-section">
            <Header toggleSideBar={this.toggleSideBar}></Header>
          </div>
        ) : null}

        <div className="body-section">
          <Outlet></Outlet>
        </div>
        <div className="footer-section">{/* ToDo: Footer section */}</div>
      </div>
    );
  }
}

export default connect((state) => state)(withRouter(AppLayout));
