import { useEffect, useState } from "react";
import { Route, Routes, Navigate, useLocation, useSearchParams } from "react-router-dom";
import { handleToken, isAuthenticated, isAuthorized, logout } from "shared/settings/auth";
import { TemplateEdit, TemplatePreview } from "features/template";
import { AppLayout } from "features/layout";
import { PageNotFound, PermissionDenied } from "features/errorPage";
import { Login } from "features/auth";
import { Campaign, ReferAFriend, TemplateList, Website, Notification, Offers, Popup } from "features/template/templateList";
import { REFRESH_TOKEN } from "shared/settings/token";

export const ProtectedRoute = ({ children }) => {
    if (!isAuthorized()) {
        // user is not authenticated
        return <Navigate to="/login" />;
    }
    return children;
};

export const AlreadyLogin = ({ children }) => {
    if (isAuthorized()) {
        // user is authenticated
        return <Navigate to="/templates/Website/templates" />;
    }
    return children;
}

const FeatureRouter = () => {
    let location = useLocation();
    const [page, setPage] = useState(false);
    const [searchParams] = useSearchParams();

    useEffect(() => {
        /** Checking user logged in or not. */
        function checkAndShowPage() {
            isAuthenticated().then(res => {
                setPage(true);
            }).catch(async error => {
                await logout();
                setPage(true);
            })
        }

        async function newToken() {
            await logout();
            localStorage.setItem(REFRESH_TOKEN, searchParams.get('secret'));
            handleToken().then(res => {
                checkAndShowPage();
            }).catch(error => {
                checkAndShowPage();
            });
        }

        if (searchParams.get('secret')) {
            newToken();
        } else {
            checkAndShowPage();
        }
    }, [searchParams]);

    useEffect(() => {
        document.title = 'Creatives Builder by Cohora' + (location.pathname.includes('login') ? ' | Login' : location.pathname.includes('home') ? ' | Home' : location.pathname.includes('templates') ? ' | Templates' : '');
    }, [location.pathname]);

    return (
        <>
            {
                page &&
                <Routes>
                    <Route path="/" element={
                        <ProtectedRoute>
                            <AppLayout />
                        </ProtectedRoute>
                    }>
                        {
                            isAuthorized() ? <Route
                                path="/"
                                element={<Navigate to="/templates/Website/templates" />}
                            /> : <Route
                                path="/"
                                element={<Navigate to="/login" />}
                            />
                        }
                        <Route exact path='/templates' element={<TemplateList />}>
                            <Route exact index path="Website/:tab" element={<Website />}></Route>
                            <Route exact path="Campaign/:tab" element={<Campaign />}></Route>
                            <Route exact path="Popup/:tab" element={<Popup />}></Route>
                            <Route exact path="Notification/:tab" element={<Notification />}></Route>
                            <Route exact path="Offers/:tab" element={<Offers />}></Route>
                            <Route exact path="ReferAFriend/:tab" element={<ReferAFriend />}></Route>
                            {/* <Route exact path="Scratchcard/:tab" element={<Scratchcard />}></Route>
                            <Route exact path="Contest/:tab" element={<Contest />}></Route> */}
                        </Route>
                        <Route exact path='/template/:type/edit/:templateId' element={<TemplateEdit />}></Route>
                    </Route>
                    <Route exact path="/template/:type/preview/:templateId" element={<ProtectedRoute><TemplatePreview /></ProtectedRoute>}></Route>
                    <Route exact path="/login" element={<AlreadyLogin><Login /></AlreadyLogin>}></Route>
                    <Route exact path="/permission-denied" element={<PermissionDenied />}></Route>
                    <Route exact path='/404' element={<PageNotFound />} />
                    <Route
                        path="*"
                        element={<Navigate to="/404" />}
                    />
                </Routes>
            }
        </>
    );
};

export default FeatureRouter;
