import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "shared/withRouter";
import {
  Templates,
  SavedTemplates,
  NotificationTemplates,
} from "./TemplateGallery";
import "./Campaign.scss";

class Campaign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: "",
    };
  }
  componentDidMount() {
    this.updateActivePage();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.router.location.pathname !== this.props.router.location.pathname
    ) {
      this.updateActivePage();
    }
  }

  updateActivePage = () => {
    const currentTab = this.props.router.params.tab;
    this.setState({
      currentTab,
    });
  };

  render() {
    return (
      <div className="campaign-container h-100">
        {this.state.currentTab ? (
          this.state.currentTab === "templates" ? (
            <Templates link="CAMPAIGN" pageIs="Campaign" />
          ) : this.state.currentTab === "notification-templates" ? (
            <NotificationTemplates link="MESSAGE_TEMPLATE" pageIs="Campaign" />
          ) : (
            <SavedTemplates link="CAMPAIGN" pageIs="Campaign" />
          )
        ) : null}
      </div>
    );
  }
}

export default connect((state) => state)(withRouter(Campaign));
