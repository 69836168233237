import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'shared/withRouter';
import { Templates, SavedTemplates } from './TemplateGallery';

import './Website.scss';

class Website extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTab: ''
        }
    }
    componentDidMount() {
        this.updateActivePage();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.router.location.pathname !== this.props.router.location.pathname) {
            this.updateActivePage();
        }
    }

    updateActivePage = () => {
        const currentTab = this.props.router.params.tab;
        this.setState({
            currentTab
        });
    }

    render() {
        return (
            <div className='website-container h-100'>
                {
                    this.state.currentTab ?
                        this.state.currentTab === 'templates' ?
                            <Templates link="LANDING_PAGE" pageIs="Website" /> :
                            <SavedTemplates link="LANDING_PAGE" pageIs="Website" />
                        : null
                }
            </div>
        );
    }
}

export default connect(state => state)(withRouter(Website));