import constants from '../constants';
import { elHasClass } from '../utils';

const Slide = (dc, config = {}) => {
    const _defaultType = dc.getType('default');
    const _defaultModel = _defaultType.model;
    const _defaultView = _defaultType.view;

    dc.addType(constants.slideName, {

        model: {
            defaults: {
                ..._defaultModel.prototype.defaults,
                name: 'Slide',
                draggable: constants.wrapperSelector,
                ...config.slideProps
            },
        },
        isComponent: (el) => {
            if (elHasClass(el, config.classSlide)) return { type: constants.slideName };
        },
        view: _defaultView
    });
}

export default Slide;