import { jwtDecode } from 'jwt-decode';

export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';
export const EXPIRES_IN = 'expiresIn';

// Stores token into the memory
export function setToken(token) {
    setAccessToken(token[ACCESS_TOKEN]);
    localStorage.setItem(REFRESH_TOKEN, token[REFRESH_TOKEN]);
    setExpiresIn(token);
}

/* sets access token on login or on new token after expired */
export function setAccessToken(token) {
    localStorage.setItem(ACCESS_TOKEN, token);
}

// Gets access token from the stored memory
export function getToken(tokenType) {
    const token = localStorage.getItem(tokenType);
    if (!token) {
        return null;
    }

    return token;
    // return AES.decrypt(JSON.parse(token), );
}

// gets the payload data from token
export function getPayload(tokenType) {
    const token = getToken(tokenType);
    if (token) {
        return jwtDecode(token);
    }

    return null;
}

// Removes tokens from the stored memory
export function unsetToken() {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    localStorage.removeItem(EXPIRES_IN);
}

// returns token expiration date
export function getTokenExpiration(tokenType) {
    const decoded = jwtDecode(getToken(tokenType));

    return new Date(decoded.exp * 1000);
}

/* Checks whether token is expired or not */
export function isTokenExpired() {
    const now = Date.now();
    if (!getToken(EXPIRES_IN)) {
        return true;
    }
    return getToken(EXPIRES_IN) < now;
}

/* Set application token */
export function setExpiresIn(token) {
    const expiresIn = Date.now() + (token[EXPIRES_IN] - 60) * 1000;
    localStorage.setItem(EXPIRES_IN, expiresIn);
}