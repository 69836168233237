import { getBaseUrl, getHeaders } from "shared/http";
import { loginAPI } from "shared/settings/apiConfig";
import InterfaceService from "./InterfaceService"
import { login } from "shared/settings/auth";

class AuthService {
    constructor() {
        this.service = InterfaceService.getService();
    }

    _login(data) {
        return this.service.post(getBaseUrl(loginAPI), JSON.stringify(data), getHeaders(false)).then(async res => {
            await login(res.data.accessToken);
            return res;
        }).catch(error => { throw (error) });
    }
}
// eslint-disable-next-line 
export default new AuthService();