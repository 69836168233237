import { BrowserRouter as Router } from 'react-router-dom';
import FeatureRouter from './features/router';
import './App.scss';

function App() {
    return (
        <div className='h-100'>
            {
                <Router>
                    <FeatureRouter></FeatureRouter>
                </Router>
            }
        </div>
    );
}

export default App;
