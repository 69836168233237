/**
 * Converting data into ascending order (or) descending order
 * 
 * @param {Array} arrayValue The array to sort
 * 
 * @param {string} field Based on this array value will sort
 * 
 * @param {boolean} ascending Ascending or descending
 * 
 * @returns Converted data
 */
 export const ArrayValueReorder = (arrayValue, field = null, ascending = true) => {

    const ascendingOrder = field ? [].concat(arrayValue).sort((a, b) => a[field].toLowerCase() > b[field].toLowerCase() ? 1 : -1) : [].concat(arrayValue).sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1);

    const descendingOrder = [].concat(ascendingOrder).reverse();

    return ascending ? ascendingOrder : descendingOrder;
}