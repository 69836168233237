import { configureStore } from '@reduxjs/toolkit';

const initialState = {
    user: {},
    refreshInterval: '',
    showHeader: true,
    showFooter: true,
    showSidebar: true,
    navigateToLogin: false,
    templateListHeightIsFull: true
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_USER':
            return Object.assign({}, state, { user: action.payload });
        case 'SET_REFRESH':
            return Object.assign({}, state, { refreshInterval: action.payload });
        case 'SHOW_LAYOUT':
            return Object.assign({}, state, { showHeader: action.payload, showFooter: action.payload, showSidebar: action.payload });
        case 'NAVIGATE_TO_LOGIN':
            return Object.assign({}, state, { navigateToLogin: action.payload })
        case 'TEMPLATE_LIST_SCREEN_HEIGHT':
            return Object.assign({}, state, { templateListHeightIsFull: action.payload })
        default:
            return state;
    }
}

const store = configureStore({
    reducer,
})
export default store;