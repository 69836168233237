/** React library */
import { Component } from "react";

/** React redux */
import { connect } from "react-redux";

/** Project library */
import { withRouter } from "shared/withRouter";
import { Templates, SavedTemplates } from "./TemplateGallery";

/** Style */
import "./Campaign.scss";
import constant from "shared/utils/constant";

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: "",
    };
  }
  componentDidMount() {
    this.updateActivePage();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.router.location.pathname !== this.props.router.location.pathname
    ) {
      this.updateActivePage();
    }
  }

  updateActivePage = () => {
    const currentTab = this.props.router.params.tab;
    this.setState({
      currentTab,
    });
  };

  render() {
    return (
      <div className="campaign-container h-100">
        {this.state.currentTab ? (
          this.state.currentTab === "templates" ? (
            <Templates
              link={constant.NOTIFICATION}
              pageIs={constant.NOTIFICATION}
            />
          ) : (
            <SavedTemplates
              link={constant.NOTIFICATION}
              pageIs={constant.NOTIFICATION}
            />
          )
        ) : null}
      </div>
    );
  }
}

export default connect((state) => state)(withRouter(Notification));
