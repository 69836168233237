const componentType = {
    primary: 'bs-button-primary',
    secondary: 'bs-button-secondary',
    info: 'bs-button-info',
    warning: 'bs-button-warn',
    danger: 'bs-button-danger',
    success: 'bs-button-success',
    customLinkBlock: 'custom-link-block'
}
const category = 'Custom';

const BSButtonPrimary = {
    model: {
        defaults: {
            components: `<button class="btn btn-primary">Primary</button>`
        }
    }
}

const btnPrimary = {
    label: `
            <div class="gjs-block__media">
                <span class="material-icons material-symbols-outlined card-slider-icon">smart_button</span>
            </div>
            <div>
                Button
            </div>
        `,
    attributes: {
        title: 'Button',
    },
    category: category,
    content: { type: componentType.primary },
}

const BSButtonSecondary = {
    model: {
        defaults: {
            components: `<button class="btn btn-secondary">Secondary</button>`
        }
    }
}

const btnSecondary = {
    label: `
            <div>
                <span class="material-icons material-symbols-outlined card-slider-icon">smart_button</span>
            </div>
            <div>
                Secondary
            </div>
        `,
    category: category,
    content: { type: componentType.secondary },
}
const BSButtonInfo = {
    model: {
        defaults: {
            components: `<button class="btn btn-info">Info</button>`
        }
    }
}

const btnInto = {
    label: `
            <div>
                <span class="material-icons material-symbols-outlined card-slider-icon">smart_button</span>
            </div>
            <div>
                Info
            </div>
        `,
    category: category,
    content: { type: componentType.info },
}
const BSButtonWarning = {
    model: {
        defaults: {
            components: `<button class="btn btn-warning">Warning</button>`
        }
    }
}

const btnWarning = {
    label: `
            <div>
                <span class="material-icons material-symbols-outlined card-slider-icon">smart_button</span>
            </div>
            <div>
                Warning
            </div>
        `,
    category: category,
    content: { type: componentType.warning },
}
const BSButtonDanger = {
    model: {
        defaults: {
            components: `<button class="btn btn-danger">Danger</button>`
        }
    }
}

const btnDanger = {
    label: `
            <div>
                <span class="material-icons material-symbols-outlined card-slider-icon">smart_button</span>
            </div>
            <div>
                Danger
            </div>
        `,
    category: category,
    content: { type: componentType.danger },
}

const BSButtonSuccess = {
    model: {
        defaults: {
            components: `<button class="btn btn-success">Success</button>`
        }
    }
}

const btnSuccess = {
    label: `
            <div>
                <span class="material-icons material-symbols-outlined card-slider-icon">smart_button</span>
            </div>
            <div>
                Success
            </div>
        `,
    category: category,
    content: { type: componentType.success },
}

const LinkScript = function (props) {
    const Opts = {
        url: props.Href,
        openIn: props.Target,
        title: props.Title
    };
    this.setAttribute('data-text', Opts.title);
    if (Opts.url) {
        this.setAttribute('onclick', `window.open("${Opts.url}", '${Opts.openIn === 'new' ? '_blank' : '_self'}')`);
    } else {
        this.removeAttribute('onclick');
    }
};

const LinkComponent = {
    model: {
        defaults: {
            script: LinkScript,
            // Define default values for your custom properties
            Href: '',
            Title: 'Learn more',
            Target: 'new',
            // Define traits, in order to change your properties
            traits: [
                {
                    type: 'text',
                    name: 'Title',
                    changeProp: true,
                },
                {
                    type: 'text',
                    name: 'Href',
                    changeProp: true,
                },
                {
                    type: 'select',
                    name: 'Target',
                    changeProp: true,
                    options: [
                        { value: 'same', name: 'This window' },
                        { value: 'new', name: 'New window' },
                    ],
                },
            ],
            'script-props': ['Href', 'Target', 'Title'],
            attributes: { class: 'btn btn-light custom-link-block' },
            styles: `
                
                .custom-link-block:after {
                    content: attr(data-text);
                }
            `,
        }
    }
};

const customLinkBlock = {
    label: `
        <div>
            <span class="material-icons material-symbols-outlined card-slider-icon">link</span>
        </div>
        <div>
            Link Block
        </div>
    `,
    category: category,
    attributes: {
        title: 'Link Block',
    },
    content: {
        type: componentType.customLinkBlock,
    }
}

export {
    BSButtonPrimary,
    BSButtonSecondary,
    BSButtonSuccess,
    BSButtonInfo,
    BSButtonWarning,
    BSButtonDanger,
    btnPrimary,
    btnSecondary,
    btnSuccess,
    btnInto,
    btnWarning,
    btnDanger,
    componentType,
    LinkComponent,
    customLinkBlock
}
