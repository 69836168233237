import { Component, createRef } from "react";
import { connect } from "react-redux";
import store from "features/store";
import { TemplateService } from "shared/services";
import { withRouter } from "shared/withRouter";
import { ArrayValueReorder } from "shared/utils/sorting";
import { dateTimeFormat } from "shared/utils/DateFormat";
import TemplateCloneModal from "./TemplateCloneModal";
import { Button, Modal, Spinner } from "react-bootstrap";
import { AiOutlineEye } from "react-icons/ai";
import { MdEdit, MdDelete } from "react-icons/md";
import { VscChromeClose } from "react-icons/vsc";
import { FaRegClone } from "react-icons/fa";
import { DatePicker } from "antd";

import "./SavedTemplates.scss";

class NotificationTemplates extends Component {
  constructor(props) {
    super(props);
    this.cloneModalRef = createRef();
    this.state = {
      templateList: [],
      pageLoading: true,
      askConfirmForDelete: false,
      deleteTemplate: null,
      deleteInProgress: false,
      activateTemplate: {
        show: false,
        template: null,
        inProgress: false,
      },
      scheduleTemplate: {
        show: false,
        date: new Date(),
      },
      currentClone: null,
    };
  }

  componentDidMount() {
    console.log("NotificationTemplates mounted");
    this.InitialData();
  }

  InitialData = () => {
    if (this.props.link) {
      TemplateService.getTemplateList({ templateType: this.props.link })
        .then((res) => {
          let data = res.data;
          data = data.filter((x) => !x.predefined);
          if (data.length > 0) {
            data = ArrayValueReorder(data, "createdAt", false);
          }
          const templateList = data;
          this.setState(
            {
              templateList: templateList,
              pageLoading: false,
            },
            () => {
              store.dispatch({
                type: "TEMPLATE_LIST_SCREEN_HEIGHT",
                payload: false,
              });
            }
          );
        })
        .catch((error) => {
          store.dispatch({
            type: "TEMPLATE_LIST_SCREEN_HEIGHT",
            payload: false,
          });
          this.setState({
            pageLoading: false,
          });
        });
    } else {
      store.dispatch({ type: "TEMPLATE_LIST_SCREEN_HEIGHT", payload: false });
      this.setState({
        pageLoading: false,
      });
    }
  };

  editTemplate = (template) => {
    this.props.router.navigate(
      `/template/${this.props.pageIs.toLowerCase()}/edit/${template.id}`,
      { state: { tab: this.props.router.params.tab } }
    );
  };

  askForDelete = (template) => {
    if (!template.active) {
      this.setState({
        askConfirmForDelete: true,
        deleteTemplate: template,
      });
    }
  };

  closeDeleteModal = () => {
    this.setState({
      askConfirmForDelete: false,
      deleteInProgress: false,
    });
  };

  deleteTemplate = () => {
    this.setState(
      {
        deleteInProgress: true,
      },
      () => {
        TemplateService.deleteTemplate(this.state.deleteTemplate.id)
          .then((res) => {
            store.dispatch({
              type: "TEMPLATE_LIST_SCREEN_HEIGHT",
              payload: true,
            });
            this.closeDeleteModal();
            this.setState(
              {
                pageLoading: true,
              },
              () => {
                this.InitialData();
              }
            );
          })
          .catch((error) => {
            this.setState({
              deleteInProgress: false,
            });
          });
      }
    );
  };

  askForActivation = (template) => {
    if (!template.active) {
      this.setState({
        activateTemplate: {
          ...this.state.activateTemplate,
          show: true,
          template: template,
        },
      });
    }
  };

  closeActivationModal = () => {
    this.setState({
      activateTemplate: {
        show: false,
        template: null,
        inProgress: false,
      },
    });
  };

  activateTemplate = () => {
    this.setState(
      {
        activateTemplate: {
          ...this.state.activateTemplate,
          inProgress: true,
        },
      },
      () => {
        const postData = {};
        this.state.activateTemplate.template.id
          .split("")
          .forEach((str, index) => {
            postData[index] = str;
          });
        TemplateService.activateTemplate(
          postData,
          this.state.activateTemplate.template.id
        )
          .then((res) => {
            this.closeActivationModal();
            this.setState(
              {
                pageLoading: true,
              },
              () => {
                this.InitialData();
              }
            );
          })
          .catch((error) => {
            this.setState({
              activateTemplate: {
                ...this.state.activateTemplate,
                show: false,
              },
            });
          });
      }
    );
  };

  previewTemplate = (template) => {
    window
      .open(
        `/template/${this.props.pageIs.toLowerCase()}/preview/${template.id}`,
        "_blank"
      )
      .focus();
  };

  openScheduleModal = () => {
    this.setState({
      scheduleTemplate: {
        ...this.state.scheduleTemplate,
        show: true,
        date: new Date(),
      },
    });
  };

  closeScheduleModal = () => {
    this.setState({
      scheduleTemplate: {
        ...this.state.scheduleTemplate,
        show: false,
      },
    });
  };

  submitSchedule = () => {
    this.setState({
      scheduleTemplate: {
        ...this.state.scheduleTemplate,
        show: false,
      },
    });
  };

  cloneTemplate = (template) => {
    this.setState({
      currentClone: template,
    });
  };

  cleanCloneTemplate = () => {
    this.setState({
      currentClone: null,
    });
  };

  render() {
    return (
      <div className="saved-template-container">
        {this.state.pageLoading ? (
          <div className={`page-loading`}>
            <div className="d-flex align-items-center justify-content-center h-100">
              <div
                className="spinner-border text-danger"
                style={{ width: "3rem", height: "3rem" }}
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="templates-section">
            <div
              className={`mt-4 table-responsive ${
                this.state.templateList.length === 0 ? "" : "min-height"
              }`}
            >
              <table
                className={`table align-middle ${
                  this.state.templateList.length === 0 ? "" : "table-hover"
                }`}
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Description</th>
                    <th scope="col">Date</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.templateList.map((template, index) => (
                    <tr className={``} key={`te_${index}`}>
                      <td>{index + 1}</td>
                      <td>
                        <div className="template-name">{template.name}</div>
                      </td>
                      <td>
                        <div className="template-description">
                          {template.description}
                        </div>
                      </td>
                      <td>{dateTimeFormat(template.createdAt)}</td>
                      <td>
                        <div className="action-section d-flex align-items-center justify-content-start">
                          <button
                            className="btn-preview"
                            onClick={() => this.previewTemplate(template)}
                          >
                            <AiOutlineEye className="h4 mb-0" />
                          </button>
                          <button
                            className="btn-clone"
                            onClick={() => this.cloneTemplate(template)}
                          >
                            <FaRegClone className="h5 mb-0" />
                          </button>
                          <button
                            className="btn-edit"
                            onClick={() => this.editTemplate(template)}
                          >
                            <MdEdit className="h4 mb-0" />
                          </button>
                          <button
                            className="btn-delete"
                            disabled={template.active}
                            onClick={() => this.askForDelete(template)}
                          >
                            <MdDelete className="h4 mb-0" />
                          </button>
                          {this.props.pageIs === "Website" && (
                            <button
                              className={`btn-schedule`}
                              onClick={this.openScheduleModal}
                            >
                              Schedule
                            </button>
                          )}
                          {this.props.pageIs === "Website" && (
                            <button
                              className={`btn-active ${
                                template.active ? "active" : ""
                              }`}
                              disabled={template.active}
                              onClick={() => this.askForActivation(template)}
                            >
                              Active
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                  {this.state.templateList.length === 0 ? (
                    <tr>
                      <td colSpan={5}>
                        <div className="no-data-found d-flex align-items-center justify-content-center">
                          No Data to Show
                        </div>
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {/* Delete modal */}
        <Modal
          show={this.state.askConfirmForDelete}
          onHide={this.closeDeleteModal}
          centered
        >
          <Modal.Body>
            <div className="template-delete-confirm text-center py-2">
              <div>
                <span className="warning-icon">&#9432;</span>
              </div>
              <div className="h4 mb-4">
                Are you sure you want to remove this template?
              </div>
              <div className="buttons-container text-center">
                <Button variant="secondary" onClick={this.closeDeleteModal}>
                  Cancel
                </Button>
                {this.state.deleteInProgress ? (
                  <Button variant="primary" disabled={true}>
                    <Spinner
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                      size="sm"
                    />
                  </Button>
                ) : (
                  <Button variant="primary" onClick={this.deleteTemplate}>
                    OK
                  </Button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Template activate modal */}
        <Modal
          show={this.state.activateTemplate.show}
          onHide={this.closeActivationModal}
          centered
        >
          <Modal.Body>
            <div className="template-delete-confirm text-center py-2">
              <div>
                <span className="warning-icon">&#9432;</span>
              </div>
              <div className="h2 mb-4">
                Are you sure you want to activate this template?
              </div>
              <div className="buttons-container text-center">
                <Button variant="secondary" onClick={this.closeActivationModal}>
                  Cancel
                </Button>
                {this.state.activateTemplate.inProgress ? (
                  <Button variant="primary" disabled={true}>
                    <Spinner
                      as="span"
                      animation="border"
                      role="status"
                      aria-hidden="true"
                      size="sm"
                    />
                  </Button>
                ) : (
                  <Button variant="primary" onClick={this.activateTemplate}>
                    OK
                  </Button>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Schedule modal */}
        <Modal
          show={this.state.scheduleTemplate.show}
          onHide={this.closeScheduleModal}
          centered
          keyboard={false}
          backdrop="static"
        >
          <Modal.Body>
            <div>
              <div className="d-flex align-items-center justify-content-end">
                <VscChromeClose className="h5 mb-0 text-danger" />
                <div>&ensp;Cancel Schedule</div>
              </div>
              <div className="my-3 w-100">
                <div className="w-100 d-flex align-items-center justify-content-center">
                  <div className="w-80">
                    <div className="mb-2">Schedule Date</div>
                    <DatePicker
                      size="large"
                      showTime={true}
                      onChange={(date) => console.log(date)}
                      className="w-100"
                    />
                  </div>
                </div>
              </div>
              <div className="text-center mt-5">
                <Button
                  variant="outline-secondary"
                  onClick={this.closeScheduleModal}
                >
                  Cancel
                </Button>
                <Button variant="info ms-2" onClick={this.submitSchedule}>
                  Schedule
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/* Clone modal */}
        <TemplateCloneModal
          template={this.state.currentClone}
          cleanCloneTemplate={this.cleanCloneTemplate}
        ></TemplateCloneModal>
      </div>
    );
  }
}

export default connect((state) => state)(withRouter(NotificationTemplates));
