import React from "react";
import { connect } from "react-redux";
import { withRouter } from "shared/withRouter";
import RemovePreLoader from "shared/utils/PreLoader";
import './PermissionDenied.scss';

class PermissionDenied extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        RemovePreLoader();
    }

    backToHome = () => {
        this.props.router.navigate('/templates/Website/templates');
    }

    render() {
        return (
            <div className="permission-denied-page">
                <div className="d-flex align-items-center justify-content-center h-100">
                    <div className="text-center">
                        <div>
                            <h2 className="oops">Oops!</h2>
                        </div>
                        <div>
                            <h4 className="denied">403 Permission Denied</h4>
                        </div>
                        <div>
                            <p>Sorry, you do not have access to this page, please contact your administrator</p>
                        </div>
                        <div>
                            <button className="back-to-home" onClick={this.backToHome}>Back To Home</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(state => state)(withRouter(PermissionDenied));