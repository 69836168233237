import constants from '../constants';
import { elHasClass } from '../utils';

const Scrollbar = (dc, config = {}) => {
    const _defaultType = dc.getType('default');
    const _defaultModel = _defaultType.model;
    const classId = config.classScrollbar;
    const type = constants.scrollbarName;

    dc.addType(type, {

        model: {
            defaults: {
                ..._defaultModel.prototype.defaults,
                name: 'Scrollbar',
                copyable: 0,
                draggable: constants.containerSelector,
                ...config.scrollbarProps
            },

            init() {
                this.get('classes').pluck('name').indexOf(classId) < 0 && this.addClass(classId);
            }
        },
        isComponent: (el) => {
            if (elHasClass(el, classId)) return { type };
        },
        view: _defaultType.view
    });
}

export default Scrollbar;