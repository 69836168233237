export const baseUrl = process.env.REACT_APP_API_URL;

/** Auth */
export const loginAPI = "/profile/v1/login";
export const refreshToken = "/profile/v1/refreshToken";

/** Common */
export const fileUpload = "/asset/v1/upload/"; // E.g /asset/v1/upload/TEMPLATE

/** User details */
export const userDetail = "/profile/v1/me";

/** Template*/
export const templateCRUD = "/template/admin/v1/template";

/** Presigned Url */
// export const presignedUrl = "/asset/v1/presignedUrl";

/** Signed Url */
export const signedUrl = "/asset/v1/signedURL";

/** Get event type list */
export const eventType = "/event/admin/v1/eventType";

/** Get event categories list */
export const eventCategories = "/event/admin/v1/eventCategories";

/** Get current tenant details */
export const currentTenant = "/tenant/v1/currentTenant";